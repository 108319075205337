<template>
  <div class="message__list__wrapper">
    <Preloader v-if="loading"/>
    <b-skeleton-table
        v-if="showSkeleton"
        :rows="15"
        :columns="3"
        :table-props="{ striped: true }"
    ></b-skeleton-table>
    <div class="table__wrapper">
      <table class="table table-hover messsage__table" v-if="messages">
        <thead class="table__header">
        <tr>
          <th>Тема</th>
          <th>Отправитель</th>
          <th>Создано</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr class="text-gray data__row"
            v-for="message in messages"
            :key="message.id"
            :class="{picked: pickedId === message.id}"
            @click="pickMessage(message)">
          <td>{{ message.subject }}</td>
          <td>{{ message.sender_name }}</td>
          <td>{{ message.created | formatDate }}</td>
          <td class="text-center">
            <div class="row__control">
              <button class="btn__action" @click.stop="detailMessage(message)" title="просмотр"><i class="fa fa-eye"
                                                                                  aria-hidden="true"></i></button>

<!--              <button class="btn__action ml-2" @click.stop="editMessage(message)" title="редактирование"><i class="fa fa-pencil" aria-hidden="true"></i></button>-->
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import MessageDetailModal from "./Modal/MessageDetailModal";
import messages from "./Notifications/NotificationsDemo/Messages";
import Preloader from "./Preloader";

export default {
  name: "EmaiMessageList",
  components: {Preloader},
  props: ['messages', 'clickHandler'],
  data() {
    return {
      pickedId: null,
      loading: false
    }
  },
  filters: {
    formatDate(val) {
      return dayjs(val).format('DD.MM.YYYY')
    }
  },
  computed: {
    showSkeleton() {
      return !this.messages
    }
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading
    },
    pickMessage(message) {
      this.pickedId = message.id
      this.clickHandler(message.id, message.subject)
    },
    async detailMessage(message) {
      this.toggleLoading();
      try {
        const {data} = await this.$axios.get('api/mailing/email/message/' + message.id)
        console.log('0000000', data)
        this.$modal.show(MessageDetailModal, {
          message: data.message,
          contact: data.contact
        }, {name: 'MessageDetailModal', width: '70%', height: 'auto', scrollable: true});
      } catch (e) {
        console.error(e)
      }
      this.toggleLoading();
    },
    editMessage(message) {
      this.$router.push({name: "EmailEdit", params: {id: message.id}})
    }
  },
  async created() {
  }
}
</script>

<style scoped lang="scss">
.data__row {
  font-weight: lighter;
  cursor: pointer;
}

.table__wrapper {
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  .message__table {
  }
}

.btn__action {
  border: none;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  height: 30px;
  width: 30px;
  background: #1A86D0;
  box-shadow: 0 4px 10px rgba(#000, .2);
  transition: .2s;
  //display: flex;
  //height: 100%;
  &:hover {
    box-shadow: 0 4px 10px rgba(#000, .3);
  }
}

.row__control {
  display: flex;
  justify-content: center;
}

.picked {
  background: darken(#eee, 10);
  color: #000 !important;

  &:hover {
    background: darken(#eee, 20);
  }
}

.table__header {
  background: #fff;
  position: sticky;
  top: 0;
}

.message__list__wrapper {

}
</style>