<template>
  <div class="p-3">
    <h3>{{ message.subject }}</h3>
    <hr/>
    <b-form-group
        label="Тема письма"
        label-for="theme"
    >
      <b-form-input id="theme" :value="message.subject" disabled trim></b-form-input>
    </b-form-group>
    <b-form-group
        label="Отправитель"
        label-for="theme"
    >
      <b-form-input id="theme" :value="message.sender_name" disabled trim></b-form-input>
    </b-form-group>

    <b-form-group
        label="Адрес отправителя"
        label-for="theme"
    >
      <b-form-input id="theme" :value="message.sender_email" disabled trim></b-form-input>
    </b-form-group>

    <b-form-group
        v-if="contact && !Array.isArray(contact)"
        label="Контакт"
    >
      <EmailContact :contact="contact" :disabled="true"/>
    </b-form-group>

    <b-form-group
        label="Шаблон"
        label-for="theme"
    >
      <div v-html="message.body"></div>
    </b-form-group>
  </div>
</template>

<script>
import EmailContact from "../EmailContact";

export default {
  name: "MessageDetailModal",
  components: {EmailContact},
  props: ['message', 'contact']
}
</script>

<style scoped>

</style>